import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AuthGuardService as AuthGuard
} from './public/auth-guard.service';

const routes: Routes = [
  {
    path: 'careers',
    loadChildren: () => import('./careers/careers.module').then(m => m.CareersModule),
  },
  // {
  //   path: 'projects',
  //   loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'experiences',
    loadChildren: () => import('./initiatives/initiatives.module').then(m => m.InitiativesModule),
  },
  {
    path: 'contracts',
    loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
  },
  // {
  //   path: 'programs',
  //   loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
  },
  {
    path: 'forum',
    loadChildren: () => import('./forum/forum.module').then(m => m.ForumModule)
  },
  {
    path: 'requests',
    loadChildren: () => import('./opportunity/opportunity.module').then(m => m.OpportunityModule),
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
  },
  {
    path: 'community',
    loadChildren: () => import('./community/community.module').then(m => m.CommunityModule),
  },
  {
    path: 'insight',
    loadChildren: () => import('./insight/insight.module').then(m => m.InsightModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'feed',
    loadChildren: () => import('./feed/feed.module').then(m => m.FeedModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'organisation',
    loadChildren: () => import('./organisation/organisation.module').then(m => m.OrganisationModule),
    canActivate: []
  },
  {
    path:'member-application-form',
    canActivate: [AuthGuard],
    loadChildren: () => import('./member-application-questions/member-application-questions.module').then(m => m.MemberApplicationQuestionsModule)
  },
  {
    path: '',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
