import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { stepperAnimation } from 'themes/mmb-web-v1-theme/animations/stepper-animation';

@Component({
  selector: 'mmb-generic-stepper',
  templateUrl: './generic-stepper.component.html',
  styleUrls: ['./generic-stepper.component.scss'],
  providers:[{ provide: CdkStepper, useExisting: GenericStepperComponent }],
  animations:[stepperAnimation]
})
export class GenericStepperComponent extends CdkStepper {

  direction = 'right';
  @ViewChild('stepsWrapper')stepsWrapper:ElementRef<HTMLElement>;

  // styleclass is to control the padding between the scrollable container and the inner content
  // or add any styling to the scrollable container
  @Input() styleClass:string = '';
  @Input() horizontalScroll:boolean = false;
  @Input() mode:'SINGLE_SAVE'|'MULTIPLE_SAVE' = 'SINGLE_SAVE';
  @Input() hasThanksMessage:boolean = false;
  @Input() set stepToNavigateTo(val:number){
    if(val !== null || val!== undefined){
      this.selectStepByIndex(val);
    }
  };

  @Output()onSubmit:EventEmitter<void> = new EventEmitter<void>();
  @Output() currentStepIndex:EventEmitter<number> = new EventEmitter<number>();
  @Output() onNextStep:EventEmitter<number> = new EventEmitter<number>();

  getAnimationDirection(index: number): void {
    if (index > this.selectedIndex) {
      this.direction = 'right';
    } else if (index < this.selectedIndex) {
      this.direction = 'left';
    }
  }

  selectStepByIndex(index: number): void {
    if(index <= this.steps.length){
      this.selectedIndex = index;
    }
  }

  goToNextStep(){
    const currentStepForm = this.steps.get(this.selectedIndex)?.stepControl;
      if(currentStepForm){
        if(currentStepForm?.invalid){
          currentStepForm.markAllAsTouched();
          return;
        }
        this.direction = 'right';
        if(this.selectedIndex === (this.hasThanksMessage ? this.steps.length -2 : this.steps.length -1)){
          if(this.mode === 'MULTIPLE_SAVE'){
            this.onNextStep.emit(this.selectedIndex);
          } else{
            this.onSubmit.emit()
          }
        } else{
          setTimeout(() => {
            if(this.mode === 'MULTIPLE_SAVE'){
              this.onNextStep.emit(this.selectedIndex);
            } else{
              this.next();
            }
            this.currentStepIndex.emit(this.selectedIndex);
            this.scrollToTop();
          }, 0);
        }
      } else{
        this.direction = 'right';
        if(this.selectedIndex === this.steps.length -1){
          this.onSubmit.emit();
        } else{
          setTimeout(() => {
            this.next();
            this.currentStepIndex.emit(this.selectedIndex);
            this.scrollToTop();
          }, 0);
        }
      }
  }

  goToPreviousStep(){
    this.direction = 'left';
      setTimeout(() => {
        this.previous();
        this.currentStepIndex.emit(this.selectedIndex);
        this.scrollToTop();
      }, 0);
  }

  scrollToTop(){
    this.stepsWrapper.nativeElement.scrollTo({
      top:0,
      behavior:'smooth'
    })
  }
}
