// animations.ts
import {
    trigger,
    state,
    style,
    transition,
    animate
  } from '@angular/animations';
  
  export const stepperAnimation = trigger('stepTransition', [
    transition('void => left', [
      style({ transform: 'translateX(-100%)' }),
      animate('300ms ease-in', style({ transform: 'translateX(0)' }))
    ]),
    transition('left => void', [
      animate('300ms ease-out', style({ transform: 'translateX(100%)' }))
    ]),
    transition('void => right', [
      style({ transform: 'translateX(100%)' }),
      animate('300ms ease-in', style({ transform: 'translateX(0)' }))
    ]),
    transition('right => void', [
      animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))
    ])
  ])